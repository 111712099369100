import React, { useState } from 'react';
import pkg from '../../package.json';
import Changelog from './Changelog';
import { ExternalLink } from 'lucide-react';

export default function Footer() {
  const [showChangelog, setShowChangelog] = useState(false);

  return (
    <>
      <footer className="sticky bottom-0 bg-white/50 dark:bg-slate-900/50 backdrop-blur-sm border-t border-slate-200 dark:border-slate-700 py-2 px-4 z-10 mt-auto">
        <div className="max-w-5xl mx-auto flex justify-end items-center gap-4">
          <button
            onClick={() => setShowChangelog(true)}
            className="text-xs text-slate-400 dark:text-slate-500 hover:text-slate-600 dark:hover:text-slate-300"
          >
            changelog
          </button>
          <span className="text-xs text-slate-400 dark:text-slate-500">
            v{pkg.version}
          </span>
          <div className="flex items-center gap-1 text-xs text-slate-400 dark:text-slate-500">
            <span>© 2024</span>
            <a
              href="https://wildapps.net"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-0.5 hover:text-slate-600 dark:hover:text-slate-300"
            >
              Wild Apps
              <ExternalLink className="w-3 h-3" />
            </a>
          </div>
        </div>
      </footer>
      {showChangelog && (
        <Changelog onClose={() => setShowChangelog(false)} />
      )}
    </>
  );
}