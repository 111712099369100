import { supabase } from '../config/supabase';

interface ImageLoadOptions {
  maxRetries?: number;
  retryDelay?: number;
  timeout?: number;
}

export async function loadSignedImageUrl(
  path: string | null, 
  { maxRetries = 3, retryDelay = 1000, timeout = 5000 }: ImageLoadOptions = {}
): Promise<string> {
  if (!path) {
    throw new Error('No image path provided');
  }

  let retryCount = 0;

  while (retryCount <= maxRetries) {
    try {
      const { data: { signedUrl }, error } = await supabase.storage
        .from('journal-covers')
        .createSignedUrl(path, 3600);

      if (error) throw error;
      if (!signedUrl) throw new Error('No signed URL returned');

      // Verify the image is accessible with timeout
      await new Promise((resolve, reject) => {
        const img = new Image();
        const timer = setTimeout(() => {
          img.src = '';  // Cancel the image load
          reject(new Error('Image load timeout'));
        }, timeout);

        img.onload = () => {
          clearTimeout(timer);
          resolve(null);
        };
        img.onerror = () => {
          clearTimeout(timer);
          reject(new Error('Image failed to load'));
        };
        img.src = signedUrl;
      });

      return signedUrl;
    } catch (error) {
      retryCount++;
      console.warn(`Image load attempt ${retryCount} failed:`, error);
      
      if (retryCount > maxRetries) {
        console.error('Image load failed permanently:', error);
        throw new Error(`Failed to load image after ${maxRetries} attempts`);
      }

      // Wait before retrying with exponential backoff
      await new Promise(resolve => setTimeout(resolve, retryDelay * Math.pow(2, retryCount - 1)));
    }
  }

  throw new Error('Failed to load image');
}