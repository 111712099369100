import CryptoJS from 'crypto-js';

const ENCRYPTION_VERSION = '1';

export function encryptContent(content: string, userId: string): string {
  if (!content || !userId) return content;
  try {
    // Use userId as encryption key (you might want to use a more secure key derivation)
    const encrypted = CryptoJS.AES.encrypt(content, userId);
    return `${ENCRYPTION_VERSION}:${encrypted.toString()}`;
  } catch (error) {
    console.error('Encryption error:', error);
    return content;
  }
}

export function decryptContent(content: string, userId: string): string {
  if (!content || !userId) return content;
  try {
    if (!content.startsWith(`${ENCRYPTION_VERSION}:`)) return content;
    const encryptedData = content.substring(content.indexOf(':') + 1);
    const decrypted = CryptoJS.AES.decrypt(encryptedData, userId);
    const result = decrypted.toString(CryptoJS.enc.Utf8);
    return result || content;
  } catch (error) {
    console.error('Decryption error:', error);
    return content;
  }
}

export function isEncrypted(content: string): boolean {
  return content?.startsWith(`${ENCRYPTION_VERSION}:`);
}