export const defaultTags = [
  'Gratitude',
  'Personal Growth',
  'Goals',
  'Reflection',
  'Mindfulness',
  'Achievements',
  'Challenges',
  'Ideas',
  'Learning',
  'Wellness'
] as const;