import { useEffect, useRef, useCallback } from 'react';

interface AutoSaveConfig {
  onSave: () => Promise<void>;
  hasUnsavedChanges: boolean;
  debounceMs?: number;
  enabled?: boolean;
}

export function useAutoSave({ 
  onSave, 
  hasUnsavedChanges, 
  debounceMs = 2000,
  enabled = true 
}: AutoSaveConfig) {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const debouncedSave = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (!enabled || !hasUnsavedChanges) return;

    timeoutRef.current = setTimeout(async () => {
      try {
        await onSave();
      } catch (error) {
        console.error('Auto-save failed:', error);
      }
    }, debounceMs);
  }, [onSave, hasUnsavedChanges, debounceMs, enabled]);

  useEffect(() => {
    debouncedSave();
    
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [debouncedSave]);
}