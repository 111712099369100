import { ChangelogEntry } from '../types/changelog';

export const changelog: ChangelogEntry[] = [
  {
    version: '1.29.0',
    date: '2024-02-16',
    changes: [
      'Added multiple AI analysis types: Summary, Polish & Enhance, and Insights & Patterns',
      'Fixed mobile menu visibility and behavior',
      'Improved mobile UI layout and accessibility',
      'Enhanced AI panel positioning and responsiveness',
      'Fixed "New Entry" button visibility in empty journal state'
    ]
  },
  {
    version: '1.28.0',
    date: '2024-02-16',
    changes: [
      'Updated AI panel label orientation',
      'Improved AI panel visual hierarchy',
      'Enhanced readability of AI controls'
    ]
  },
  {
    version: '1.27.0',
    date: '2024-02-16',
    changes: [
      'Added "AI" label above analysis panel icons',
      'Improved AI panel visual hierarchy',
      'Enhanced AI panel usability'
    ]
  }
];