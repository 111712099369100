import React, { useState, useEffect } from 'react';
import { Loader2, ChevronRight, ChevronLeft } from 'lucide-react';
import { AIAnalysis, AnalysisType } from '../types/aiAnalysis';
import { analysisTypes } from '../constants/analysisTypes';
import { formatDateTime } from '../utils/dateUtils';

interface AIAnalysisPanelProps {
  content: string;
  analyses: AIAnalysis[];
  isGenerating: boolean;
  onGenerate: (type: AnalysisType) => void;
}

export default function AIAnalysisPanel({ content, analyses, isGenerating, onGenerate }: AIAnalysisPanelProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [lastContent, setLastContent] = useState(content);
  const [selectedType, setSelectedType] = useState<AnalysisType>('summary');

  // Auto-generate analysis when content changes significantly
  useEffect(() => {
    if (!content.trim() || content === lastContent) return;

    const words = content.trim().split(/\s+/);
    const lastWords = lastContent.trim().split(/\s+/);
    
    // Only generate if content has changed by at least 20 words
    if (Math.abs(words.length - lastWords.length) >= 20) {
      onGenerate(selectedType);
      setLastContent(content);
    }
  }, [content, lastContent, onGenerate, selectedType]);

  const currentAnalysis = analyses.find(a => a.type === selectedType);
  const selectedAnalysisType = analysisTypes[selectedType];

  if (!content.trim()) {
    return null;
  }

  return (
    <div 
      className={`fixed right-0 top-[8rem] md:top-[10rem] h-[calc(100vh-14rem)] md:h-[calc(100vh-16rem)] bg-white dark:bg-slate-800 border-l border-slate-200 dark:border-slate-700 transition-all duration-300 z-20 ${
        isExpanded ? 'w-[calc(100%-3rem)] md:w-96' : 'w-12'
      } flex`}
    >
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-1/2 w-8 h-8 rounded-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 flex items-center justify-center hover:bg-slate-50 dark:hover:bg-slate-700 transition-colors z-10"
      >
        {isExpanded ? (
          <ChevronRight className="w-4 h-4 text-slate-600 dark:text-slate-300" />
        ) : (
          <ChevronLeft className="w-4 h-4 text-slate-600 dark:text-slate-300" />
        )}
      </button>

      <div className={`w-12 shrink-0 border-r border-slate-200 dark:border-slate-700 flex flex-col items-center py-4 ${isExpanded ? '' : 'border-none'}`}>
        <div className="flex flex-col items-center gap-4">
          <span className="text-xs font-medium text-slate-500 dark:text-slate-400 mb-2">
            AI
          </span>
          {Object.values(analysisTypes).map(type => {
            const Icon = type.icon;
            return (
              <button
                key={type.id}
                onClick={() => setSelectedType(type.id)}
                className={`p-2 rounded-lg transition-colors ${
                  selectedType === type.id
                    ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-500 dark:text-blue-400'
                    : 'text-slate-400 dark:text-slate-500 hover:bg-slate-100 dark:hover:bg-slate-700'
                }`}
                title={type.label}
              >
                <Icon className="w-5 h-5" />
              </button>
            );
          })}
        </div>
      </div>

      {isExpanded && (
        <div className="flex-1 p-4 overflow-y-auto">
          <div className="mb-4">
            <h3 className="text-lg font-medium text-slate-700 dark:text-slate-200 mb-1">
              {selectedAnalysisType.label}
            </h3>
            <p className="text-sm text-slate-500 dark:text-slate-400">
              {selectedAnalysisType.description}
            </p>
          </div>

          <div className="flex justify-end mb-4">
            <button
              onClick={() => onGenerate(selectedType)}
              disabled={isGenerating}
              className="text-xs px-3 py-1.5 rounded-full bg-blue-500 text-white hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isGenerating ? 'Generating...' : (currentAnalysis ? 'Regenerate' : 'Generate')}
            </button>
          </div>

          {isGenerating && !currentAnalysis && (
            <div className="flex items-center gap-2 text-sm text-slate-500 dark:text-slate-400 p-3 bg-slate-50 dark:bg-slate-800/50 rounded">
              <Loader2 className="w-4 h-4 animate-spin" />
              <span>Creating {selectedAnalysisType.label.toLowerCase()}...</span>
            </div>
          )}
          
          {currentAnalysis && (
            <div className="text-sm text-slate-600 dark:text-slate-300 p-4 bg-slate-50 dark:bg-slate-800/50 rounded-lg">
              <div className="space-y-2 whitespace-pre-wrap">
                {currentAnalysis.content}
              </div>
              <div className="mt-3 text-xs text-slate-400 dark:text-slate-500">
                Last updated: {formatDateTime(currentAnalysis.createdAt)}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}