import { Star, Sparkles, Lightbulb } from 'lucide-react';
import { AIAnalysisType } from '../types/aiAnalysis';

export const analysisTypes: Record<string, AIAnalysisType> = {
  summary: {
    id: 'summary',
    label: 'Quick Summary',
    description: 'A concise overview of your entry for easy reference',
    icon: Star,
    prompt: 'Provide a clear and concise summary of this journal entry, highlighting the main points and key thoughts.'
  },
  format: {
    id: 'format',
    label: 'Polish & Enhance',
    description: 'Improve readability while preserving your voice',
    icon: Sparkles,
    prompt: 'Format and polish this text to improve readability while maintaining the original voice and style. Fix grammar and punctuation but keep the personal tone intact.'
  },
  analysis: {
    id: 'analysis',
    label: 'Insights & Patterns',
    description: 'Discover meaningful patterns and reflections',
    icon: Lightbulb,
    prompt: 'Analyze this journal entry thoughtfully. Identify key themes, emotional patterns, and meaningful insights while maintaining a supportive and understanding tone.'
  }
} as const;