import { format, isValid, parseISO } from 'date-fns';

export function formatDate(dateString: string | null | undefined, fallback = 'Unknown date'): string {
  if (!dateString) return fallback;
  
  try {
    // First try parsing as ISO string
    const parsedDate = parseISO(dateString);
    if (isValid(parsedDate)) {
      return format(parsedDate, 'MMMM do, yyyy');
    }

    // If that fails, try creating a new Date
    const date = new Date(dateString);
    if (isValid(date)) {
      return format(date, 'MMMM do, yyyy');
    }

    console.error('Invalid date:', dateString);
    return fallback;
  } catch (error) {
    console.error('Date formatting error:', error);
    return fallback;
  }
}

export function formatDateTime(dateString: string | null | undefined, fallback = 'Unknown date'): string {
  if (!dateString) return fallback;
  
  try {
    // First try parsing as ISO string
    const parsedDate = parseISO(dateString);
    if (isValid(parsedDate)) {
      return parsedDate.toLocaleString();
    }

    // If that fails, try creating a new Date
    const date = new Date(dateString);
    if (isValid(date)) {
      return date.toLocaleString();
    }

    console.error('Invalid date:', dateString);
    return fallback;
  } catch (error) {
    console.error('Date formatting error:', error);
    return fallback;
  }
}