import React, { useState, useRef } from 'react';
import { X, Upload, Trash2, AlertTriangle, Loader2, AlertCircle } from 'lucide-react';
import { useCollectionStore } from '../../store/useCollectionStore';
import { JournalCollection } from '../../types/journal';
import { supabase } from '../../config/supabase';
import { useAuth } from '../../contexts/AuthContext';
import { v4 as uuidv4 } from 'uuid';

interface JournalSettingsModalProps {
  collection: JournalCollection;
  onClose: () => void;
}

export default function JournalSettingsModal({ collection, onClose }: JournalSettingsModalProps) {
  const [name, setName] = useState(collection.name);
  const [description, setDescription] = useState(collection.description);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteText, setDeleteText] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { user } = useAuth();
  
  const { updateCollection, deleteCollection } = useCollectionStore();

  // Load the signed URL when component mounts
  React.useEffect(() => {
    const loadSignedUrl = async () => {
      if (collection.coverImage) {
        try {
          const path = collection.coverImage.split('/').slice(-2).join('/');
          const { data: { signedUrl }, error } = await supabase.storage
            .from('journal-covers')
            .createSignedUrl(path, 3600);

          if (error) throw error;
          setPreviewUrl(signedUrl);
        } catch (error) {
          console.error('Error loading image:', error);
          setError('Failed to load cover image');
        }
      }
    };

    loadSignedUrl();
  }, [collection.coverImage]);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setError('Please select an image file');
        return;
      }
      
      if (file.size > 5 * 1024 * 1024) {
        setError('Image size must be less than 5MB');
        return;
      }

      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (file: File): Promise<string> => {
    if (!user) throw new Error('User not authenticated');

    const fileExt = file.name.split('.').pop();
    const fileName = `${uuidv4()}.${fileExt}`;
    const filePath = `${user.id}/${fileName}`;

    try {
      const { error: uploadError } = await supabase.storage
        .from('journal-covers')
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: true
        });

      if (uploadError) throw uploadError;
      return filePath;
    } catch (error: any) {
      console.error('Upload error:', error);
      throw new Error('Failed to upload image: ' + error.message);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!name.trim()) {
      setError('Please provide a name for your journal');
      return;
    }

    setLoading(true);
    setError('');

    try {
      let coverImage = collection.coverImage;

      if (selectedFile) {
        try {
          if (collection.coverImage) {
            const oldPath = collection.coverImage;
            await supabase.storage
              .from('journal-covers')
              .remove([oldPath]);
          }
          coverImage = await uploadImage(selectedFile);
        } catch (error: any) {
          setError('Failed to upload image: ' + error.message);
          return;
        }
      }

      await updateCollection(collection.id, {
        name: name.trim(),
        description: description.trim(),
        coverImage
      });
      onClose();
    } catch (error: any) {
      console.error('Update collection error:', error);
      setError(error.message || 'Failed to update journal');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (deleteText !== 'DELETE') {
      setError('Please type DELETE to confirm');
      return;
    }

    setLoading(true);
    setError('');

    try {
      if (collection.coverImage) {
        await supabase.storage
          .from('journal-covers')
          .remove([collection.coverImage]);
      }

      await deleteCollection(collection.id);
      onClose();
    } catch (error: any) {
      console.error('Delete collection error:', error);
      setError(error.message || 'Failed to delete journal');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-white dark:bg-slate-800 rounded-lg shadow-xl max-w-md w-full max-h-[90vh] flex flex-col">
        <div className="p-6 border-b border-slate-200 dark:border-slate-700 flex items-center justify-between">
          <h2 className="text-xl font-semibold text-slate-900 dark:text-white">
            Journal Settings
          </h2>
          <button
            onClick={onClose}
            className="text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-6 overflow-y-auto">
          {error && (
            <div className="mb-4 p-3 rounded bg-red-100 dark:bg-red-900/30 text-red-600 dark:text-red-300 text-sm flex items-center gap-2">
              <AlertCircle className="w-4 h-4 shrink-0" />
              <span>{error}</span>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-slate-700 dark:text-slate-300 mb-1">
                Journal Name
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full px-3 py-2 rounded-lg border border-slate-300 dark:border-slate-600 bg-white dark:bg-slate-800 text-slate-900 dark:text-white focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600"
                placeholder="My Personal Journal"
                required
                maxLength={100}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-slate-700 dark:text-slate-300 mb-1">
                Description
              </label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full px-3 py-2 rounded-lg border border-slate-300 dark:border-slate-600 bg-white dark:bg-slate-800 text-slate-900 dark:text-white focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600 resize-none"
                placeholder="What's this journal about?"
                rows={3}
                maxLength={500}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-slate-700 dark:text-slate-300 mb-1">
                Cover Image
              </label>
              <div className="mt-2 flex items-center gap-4">
                {previewUrl && (
                  <img
                    src={previewUrl}
                    alt="Cover preview"
                    className="w-20 h-20 object-cover rounded-lg"
                  />
                )}
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileSelect}
                  accept="image/*"
                  className="hidden"
                />
                <button
                  type="button"
                  onClick={() => fileInputRef.current?.click()}
                  className="px-4 py-2 rounded-lg border border-slate-200 dark:border-slate-700 text-slate-600 dark:text-slate-300 hover:bg-slate-50 dark:hover:bg-slate-700/50 flex items-center gap-2"
                >
                  <Upload className="w-4 h-4" />
                  Choose Image
                </button>
              </div>
              <p className="mt-2 text-xs text-slate-500 dark:text-slate-400">
                Maximum file size: 5MB. Supported formats: JPG, PNG, GIF
              </p>
            </div>

            <div className="flex justify-end gap-3 pt-4">
              <button
                type="button"
                onClick={() => setShowDeleteConfirm(true)}
                className="px-4 py-2 rounded-lg border border-red-200 dark:border-red-900/50 text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20 flex items-center gap-2"
              >
                <Trash2 className="w-4 h-4" />
                Delete Journal
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
              >
                {loading ? (
                  <>
                    <Loader2 className="w-4 h-4 animate-spin" />
                    Saving...
                  </>
                ) : (
                  'Save Changes'
                )}
              </button>
            </div>
          </form>

          {showDeleteConfirm && (
            <div className="mt-6 p-4 rounded-lg bg-red-50 dark:bg-red-900/30 border border-red-200 dark:border-red-900/50">
              <div className="flex items-start gap-3 mb-4">
                <AlertTriangle className="w-5 h-5 text-red-600 dark:text-red-400 shrink-0 mt-0.5" />
                <div>
                  <h3 className="text-lg font-medium text-red-600 dark:text-red-400">
                    Delete Journal
                  </h3>
                  <p className="text-sm text-red-600/90 dark:text-red-400/90 mt-1">
                    This action cannot be undone. All entries in this journal will be permanently deleted.
                  </p>
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-red-600 dark:text-red-400 mb-1">
                  Type DELETE to confirm
                </label>
                <input
                  type="text"
                  value={deleteText}
                  onChange={(e) => setDeleteText(e.target.value)}
                  className="w-full px-3 py-2 rounded-lg border border-red-200 dark:border-red-900/50 bg-white dark:bg-slate-800 text-red-600 dark:text-red-400 focus:ring-2 focus:ring-red-200 dark:focus:ring-red-900/50 focus:outline-none"
                  placeholder="DELETE"
                />
              </div>
              <div className="flex justify-end gap-3">
                <button
                  onClick={() => setShowDeleteConfirm(false)}
                  className="px-4 py-2 rounded-lg border border-slate-200 dark:border-slate-700 text-slate-600 dark:text-slate-300 hover:bg-slate-50 dark:hover:bg-slate-700/50"
                >
                  Cancel
                </button>
                <button
                  onClick={handleDelete}
                  disabled={deleteText !== 'DELETE' || loading}
                  className="px-4 py-2 rounded-lg bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
                >
                  {loading ? (
                    <>
                      <Loader2 className="w-4 h-4 animate-spin" />
                      Deleting...
                    </>
                  ) : (
                    <>
                      <Trash2 className="w-4 h-4" />
                      Delete Forever
                    </>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}