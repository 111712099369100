import React from 'react';
import { Book, ImageOff } from 'lucide-react';
import { useCollectionStore } from '../store/useCollectionStore';
import { loadSignedImageUrl } from '../utils/imageLoader';

interface CoverImageStripProps {
  collectionId: string;
}

export default function CoverImageStrip({ collectionId }: CoverImageStripProps) {
  const [imageUrl, setImageUrl] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const { collections } = useCollectionStore();
  const collection = collections.find(c => c.id === collectionId);

  React.useEffect(() => {
    let mounted = true;
    let retryTimeout: NodeJS.Timeout;

    const loadCoverImage = async () => {
      if (!collection?.coverImage) {
        if (mounted) setLoading(false);
        return;
      }

      try {
        if (mounted) setError(false);
        const signedUrl = await loadSignedImageUrl(collection.coverImage, {
          maxRetries: 3,
          retryDelay: 1000,
          timeout: 5000
        });
        
        if (mounted) {
          setImageUrl(signedUrl);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error loading cover image:', error);
        if (mounted) {
          setError(true);
          setLoading(false);

          // Retry after 5 seconds
          retryTimeout = setTimeout(loadCoverImage, 5000);
        }
      }
    };

    loadCoverImage();

    return () => {
      mounted = false;
      if (retryTimeout) clearTimeout(retryTimeout);
    };
  }, [collection]);

  if (!collection) return null;

  return (
    <div className="relative h-32 -mx-4 -mt-6 mb-6 overflow-hidden">
      {loading ? (
        <div className="absolute inset-0 bg-slate-100 dark:bg-slate-800 animate-pulse" />
      ) : error || !imageUrl ? (
        <div className="absolute inset-0 bg-gradient-to-br from-blue-100 to-indigo-100 dark:from-blue-900/30 dark:to-indigo-900/30 flex items-center justify-center">
          {error ? (
            <div className="flex flex-col items-center gap-2">
              <ImageOff className="w-8 h-8 text-slate-400" />
              <span className="text-sm text-slate-500">Failed to load cover image</span>
            </div>
          ) : (
            <Book className="w-12 h-12 text-blue-500/50" />
          )}
        </div>
      ) : (
        <img
          src={imageUrl}
          alt={collection.name}
          className="w-full h-full object-cover"
          onError={() => setError(true)}
        />
      )}
      <div className="absolute inset-0 bg-gradient-to-t from-white dark:from-slate-900 to-transparent" />
      <div className="absolute bottom-0 left-0 right-0 p-4">
        <h1 className="text-2xl font-medium text-slate-800 dark:text-slate-200">
          {collection.name}
        </h1>
        {collection.description && (
          <p className="text-sm text-slate-600 dark:text-slate-400 line-clamp-2">
            {collection.description}
          </p>
        )}
      </div>
    </div>
  );
}