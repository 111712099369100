import React from 'react';
import { Save, Loader2, Check } from 'lucide-react';

interface FloatingSaveButtonProps {
  onSave: () => void;
  isSaving: boolean;
  hasUnsavedChanges: boolean;
  justSaved: boolean;
  disabled?: boolean;
}

export default function FloatingSaveButton({ 
  onSave, 
  isSaving, 
  hasUnsavedChanges,
  justSaved,
  disabled 
}: FloatingSaveButtonProps) {
  return (
    <button
      onClick={onSave}
      disabled={disabled || isSaving || (!hasUnsavedChanges && !justSaved)}
      className="fixed bottom-20 right-4 z-20 p-4 rounded-full bg-blue-500 text-white shadow-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 flex items-center justify-center"
    >
      {isSaving ? (
        <Loader2 className="w-6 h-6 animate-spin" />
      ) : justSaved ? (
        <Check className="w-6 h-6" />
      ) : (
        <Save className="w-6 h-6" />
      )}
    </button>
  );
}