import { create } from 'zustand';
import { supabase } from '../config/supabase';
import { JournalCollection } from '../types/journal';

interface CreateCollectionData {
  name: string;
  description: string;
  coverImage?: string | null;
  userId: string;
}

interface CollectionState {
  collections: JournalCollection[];
  selectedCollectionId: string | null;
  loading: boolean;
  error: string | null;
  fetchCollections: (userId: string) => Promise<void>;
  createCollection: (data: CreateCollectionData) => Promise<string>;
  updateCollection: (id: string, data: Partial<CreateCollectionData>) => Promise<void>;
  deleteCollection: (id: string) => Promise<void>;
  selectCollection: (id: string | null) => void;
}

export const useCollectionStore = create<CollectionState>((set, get) => ({
  collections: [],
  selectedCollectionId: null,
  loading: false,
  error: null,

  fetchCollections: async (userId: string) => {
    if (!userId) {
      set({ error: 'User ID is required', loading: false });
      return;
    }
    
    set({ loading: true, error: null });

    try {
      const { data: collections, error } = await supabase
        .from('journal_collections')
        .select('*, journal_entries(count)')
        .eq('user_id', userId)
        .order('created_at', { ascending: false });

      if (error) {
        throw new Error(`Failed to fetch collections: ${error.message}`);
      }

      if (!collections) {
        throw new Error('No collections data received');
      }

      const collectionsWithCount = collections.map(collection => ({
        id: collection.id,
        name: collection.name,
        description: collection.description,
        coverImage: collection.cover_image,
        createdAt: collection.created_at,
        updatedAt: collection.updated_at,
        userId: collection.user_id,
        entryCount: collection.journal_entries?.[0]?.count || 0
      }));

      set({ collections: collectionsWithCount, loading: false, error: null });
    } catch (error: any) {
      console.error('Fetch collections error:', error);
      set({ 
        loading: false, 
        error: error.message || 'Failed to fetch collections',
        collections: []
      });
    }
  },

  createCollection: async (data: CreateCollectionData) => {
    try {
      const timestamp = new Date().toISOString();
      
      const { data: newCollection, error } = await supabase
        .from('journal_collections')
        .insert([{
          user_id: data.userId,
          name: data.name,
          description: data.description,
          cover_image: data.coverImage || null,
          created_at: timestamp,
          updated_at: timestamp
        }])
        .select('*')
        .single();

      if (error) {
        throw new Error(`Failed to create collection: ${error.message}`);
      }

      if (!newCollection) {
        throw new Error('No data returned from collection creation');
      }

      const collection: JournalCollection = {
        id: newCollection.id,
        name: newCollection.name,
        description: newCollection.description,
        coverImage: newCollection.cover_image,
        createdAt: newCollection.created_at,
        updatedAt: newCollection.updated_at,
        userId: newCollection.user_id,
        entryCount: 0
      };

      set(state => ({
        collections: [collection, ...state.collections]
      }));

      return collection.id;
    } catch (error: any) {
      console.error('Create collection error:', error);
      throw new Error(error.message || 'Failed to create collection');
    }
  },

  updateCollection: async (id: string, data: Partial<CreateCollectionData>) => {
    try {
      const timestamp = new Date().toISOString();
      
      const { data: updatedCollection, error } = await supabase
        .from('journal_collections')
        .update({
          name: data.name,
          description: data.description,
          cover_image: data.coverImage,
          updated_at: timestamp
        })
        .eq('id', id)
        .select('*')
        .single();

      if (error) {
        throw new Error(`Failed to update collection: ${error.message}`);
      }

      if (!updatedCollection) {
        throw new Error('No data returned from collection update');
      }

      set(state => ({
        collections: state.collections.map(collection =>
          collection.id === id
            ? {
                ...collection,
                name: updatedCollection.name,
                description: updatedCollection.description,
                coverImage: updatedCollection.cover_image,
                updatedAt: updatedCollection.updated_at
              }
            : collection
        )
      }));
    } catch (error: any) {
      console.error('Update collection error:', error);
      throw new Error(error.message || 'Failed to update collection');
    }
  },

  deleteCollection: async (id: string) => {
    try {
      const { error } = await supabase
        .from('journal_collections')
        .delete()
        .eq('id', id);

      if (error) {
        throw new Error(`Failed to delete collection: ${error.message}`);
      }

      set(state => ({
        collections: state.collections.filter(c => c.id !== id),
        selectedCollectionId: state.selectedCollectionId === id ? null : state.selectedCollectionId
      }));
    } catch (error: any) {
      console.error('Delete collection error:', error);
      throw new Error(error.message || 'Failed to delete collection');
    }
  },

  selectCollection: (id: string | null) => {
    set({ selectedCollectionId: id });
  }
}));