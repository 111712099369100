import React from 'react';
import { X } from 'lucide-react';
import { changelog } from '../data/changelog';

interface ChangelogProps {
  onClose: () => void;
}

export default function Changelog({ onClose }: ChangelogProps) {
  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white dark:bg-slate-800 w-full max-w-lg rounded-lg shadow-xl m-4">
        <div className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-slate-700">
          <h2 className="text-xl font-semibold text-slate-700 dark:text-slate-200">
            Changelog
          </h2>
          <button
            onClick={onClose}
            className="p-1 rounded-full hover:bg-slate-100 dark:hover:bg-slate-700 text-slate-500 dark:text-slate-400"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        <div className="p-4 max-h-[70vh] overflow-y-auto">
          {changelog.map((entry, index) => (
            <div
              key={entry.version}
              className={`${
                index > 0 ? 'mt-6' : ''
              }`}
            >
              <div className="flex items-baseline gap-3 mb-2">
                <h3 className="text-lg font-medium text-slate-700 dark:text-slate-200">
                  v{entry.version}
                </h3>
                <span className="text-sm text-slate-500 dark:text-slate-400">
                  {entry.date}
                </span>
              </div>
              <ul className="space-y-2">
                {entry.changes.map((change, i) => (
                  <li
                    key={i}
                    className="flex items-start gap-2 text-slate-600 dark:text-slate-300"
                  >
                    <span className="mt-1.5 w-1.5 h-1.5 rounded-full bg-blue-500 shrink-0" />
                    <span>{change}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}