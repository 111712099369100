import React, { useState, useEffect } from 'react';
import { 
  X, 
  PenLine, 
  Calendar, 
  Moon, 
  Sun, 
  LogOut, 
  Bot, 
  UserCircle, 
  ChevronLeft, 
  ChevronRight, 
  Settings, 
  Library,
  Grid
} from 'lucide-react';
import { useTheme } from '../contexts/ThemeContext';
import { useAuth } from '../contexts/AuthContext';
import { useCollectionStore } from '../store/useCollectionStore';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  onShowEntries: () => void;
  onNewEntry: () => void;
  onShowProfile: () => void;
  onShowSettings: () => void;
  onShowCollections: () => void;
  showCollectionsButton: boolean;
}

export default function Sidebar({ 
  isOpen, 
  onClose, 
  onShowEntries, 
  onNewEntry, 
  onShowProfile, 
  onShowSettings,
  onShowCollections,
  showCollectionsButton 
}: SidebarProps) {
  const { isDark, isAIEnabled, toggleTheme, toggleAI } = useTheme();
  const { user, logOut } = useAuth();
  const { selectedCollectionId } = useCollectionStore();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth >= 768) {
        setIsCollapsed(true);
      }
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleNavClick = (action: () => void) => {
    action();
    if (isMobile) {
      onClose();
    }
  };

  return (
    <>
      {/* Mobile overlay */}
      {isMobile && isOpen && (
        <div 
          className="fixed inset-0 bg-black/20 backdrop-blur-sm z-20"
          onClick={onClose}
        />
      )}

      <div
        className={`fixed inset-y-0 left-0 transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } ${isCollapsed ? 'w-20' : 'w-64'} bg-white dark:bg-slate-900 border-r border-slate-200 dark:border-slate-700 transition-all duration-200 ease-in-out ${
          isMobile ? 'z-30' : 'md:relative md:translate-x-0'
        } flex flex-col`}
      >
        {/* Toggle collapse button - only show on desktop */}
        {!isMobile && (
          <button
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="absolute -right-3 top-1/2 transform -translate-y-1/2 w-6 h-12 bg-white dark:bg-slate-900 border border-slate-200 dark:border-slate-700 rounded-r-lg flex items-center justify-center hover:bg-slate-50 dark:hover:bg-slate-800 transition-colors duration-200 group"
          >
            {isCollapsed ? (
              <ChevronRight className="w-4 h-4 text-slate-400 group-hover:text-slate-600 dark:text-slate-500 dark:group-hover:text-slate-300" />
            ) : (
              <ChevronLeft className="w-4 h-4 text-slate-400 group-hover:text-slate-600 dark:text-slate-500 dark:group-hover:text-slate-300" />
            )}
          </button>
        )}

        <div className="flex-1 flex flex-col min-h-0">
          <div className="p-4 border-b border-slate-200 dark:border-slate-700">
            <div className="flex items-center justify-between mb-2">
              <h2 className={`text-xl font-semibold text-slate-700 dark:text-slate-200 transition-opacity duration-200 ${isCollapsed ? 'opacity-0' : 'opacity-100'}`}>
                Living Journal
              </h2>
              {isMobile && isOpen && !isCollapsed && (
                <button
                  onClick={onClose}
                  className="p-2 rounded-full hover:bg-slate-100 dark:hover:bg-slate-800"
                >
                  <X className="w-5 h-5 text-slate-500" />
                </button>
              )}
            </div>
            {user && (
              <div className={`flex items-center ${isCollapsed ? 'justify-center' : 'gap-2'}`}>
                <button
                  onClick={() => handleNavClick(onShowProfile)}
                  className="p-1 rounded-full hover:bg-slate-100 dark:hover:bg-slate-800 text-slate-500 dark:text-slate-400"
                  title={isCollapsed ? user.email : undefined}
                >
                  <UserCircle className="w-5 h-5" />
                </button>
                <p className={`text-sm text-slate-500 dark:text-slate-400 truncate transition-opacity duration-200 ${isCollapsed ? 'hidden' : 'block'}`}>
                  {user.email}
                </p>
              </div>
            )}
          </div>

          <nav className="flex-1 p-4 space-y-2">
            {!selectedCollectionId ? (
              <>
                <button
                  onClick={() => handleNavClick(onShowCollections)}
                  className={`w-full flex items-center gap-3 px-4 py-2 text-left rounded-lg hover:bg-slate-100 dark:hover:bg-slate-800 text-slate-700 dark:text-slate-200 ${isCollapsed ? 'justify-center' : ''}`}
                  title={isCollapsed ? 'My Journals' : undefined}
                >
                  <Grid className="w-5 h-5" />
                  <span className={`transition-opacity duration-200 ${isCollapsed ? 'hidden' : 'block'}`}>
                    My Journals
                  </span>
                </button>
              </>
            ) : (
              <>
                {showCollectionsButton && (
                  <button
                    onClick={() => handleNavClick(onShowCollections)}
                    className={`w-full flex items-center gap-3 px-4 py-2 text-left rounded-lg hover:bg-slate-100 dark:hover:bg-slate-800 text-slate-700 dark:text-slate-200 ${isCollapsed ? 'justify-center' : ''}`}
                    title={isCollapsed ? 'Back to Journals' : undefined}
                  >
                    <Library className="w-5 h-5" />
                    <span className={`transition-opacity duration-200 ${isCollapsed ? 'hidden' : 'block'}`}>
                      Back to Journals
                    </span>
                  </button>
                )}
                <button
                  onClick={() => handleNavClick(onNewEntry)}
                  className={`w-full flex items-center gap-3 px-4 py-2 text-left rounded-lg hover:bg-slate-100 dark:hover:bg-slate-800 text-slate-700 dark:text-slate-200 ${isCollapsed ? 'justify-center' : ''}`}
                  title={isCollapsed ? 'New Entry' : undefined}
                >
                  <PenLine className="w-5 h-5" />
                  <span className={`transition-opacity duration-200 ${isCollapsed ? 'hidden' : 'block'}`}>
                    New Entry
                  </span>
                </button>
                <button
                  onClick={() => handleNavClick(onShowEntries)}
                  className={`w-full flex items-center gap-3 px-4 py-2 text-left rounded-lg hover:bg-slate-100 dark:hover:bg-slate-800 text-slate-700 dark:text-slate-200 ${isCollapsed ? 'justify-center' : ''}`}
                  title={isCollapsed ? 'View Entries' : undefined}
                >
                  <Calendar className="w-5 h-5" />
                  <span className={`transition-opacity duration-200 ${isCollapsed ? 'hidden' : 'block'}`}>
                    View Entries
                  </span>
                </button>
              </>
            )}
          </nav>

          <div className="p-4 border-t border-slate-200 dark:border-slate-700 space-y-2">
            <button
              onClick={() => handleNavClick(onShowSettings)}
              className={`w-full flex items-center gap-3 px-4 py-2 text-left rounded-lg hover:bg-slate-100 dark:hover:bg-slate-800 text-slate-700 dark:text-slate-200 ${isCollapsed ? 'justify-center' : ''}`}
              title={isCollapsed ? 'AI Settings' : undefined}
            >
              <Bot className="w-5 h-5" />
              <span className={`transition-opacity duration-200 ${isCollapsed ? 'hidden' : 'block'}`}>
                AI Settings
              </span>
            </button>
            <button
              onClick={toggleTheme}
              className={`w-full flex items-center gap-3 px-4 py-2 text-left rounded-lg hover:bg-slate-100 dark:hover:bg-slate-800 text-slate-700 dark:text-slate-200 ${isCollapsed ? 'justify-center' : ''}`}
              title={isCollapsed ? (isDark ? 'Light Mode' : 'Dark Mode') : undefined}
            >
              {isDark ? <Sun className="w-5 h-5" /> : <Moon className="w-5 h-5" />}
              <span className={`transition-opacity duration-200 ${isCollapsed ? 'hidden' : 'block'}`}>
                {isDark ? 'Light Mode' : 'Dark Mode'}
              </span>
            </button>
            <button
              onClick={() => handleNavClick(onShowSettings)}
              className={`w-full flex items-center gap-3 px-4 py-2 text-left rounded-lg hover:bg-slate-100 dark:hover:bg-slate-800 text-slate-700 dark:text-slate-200 ${isCollapsed ? 'justify-center' : ''}`}
              title={isCollapsed ? 'Settings' : undefined}
            >
              <Settings className="w-5 h-5" />
              <span className={`transition-opacity duration-200 ${isCollapsed ? 'hidden' : 'block'}`}>
                Settings
              </span>
            </button>
            <button
              onClick={logOut}
              className={`w-full flex items-center gap-3 px-4 py-2 text-left rounded-lg hover:bg-slate-100 dark:hover:bg-slate-800 text-slate-700 dark:text-slate-200 ${isCollapsed ? 'justify-center' : ''}`}
              title={isCollapsed ? 'Sign Out' : undefined}
            >
              <LogOut className="w-5 h-5" />
              <span className={`transition-opacity duration-200 ${isCollapsed ? 'hidden' : 'block'}`}>
                Sign Out
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}