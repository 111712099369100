import React, { createContext, useContext, useState, useEffect } from 'react';

type AIPerspective = 'first' | 'second' | 'third';

interface SettingsContextType {
  aiPerspective: AIPerspective;
  setAiPerspective: (perspective: AIPerspective) => void;
}

const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

export function SettingsProvider({ children }: { children: React.ReactNode }) {
  const [aiPerspective, setAiPerspective] = useState<AIPerspective>(() => {
    const saved = localStorage.getItem('aiPerspective');
    return (saved as AIPerspective) || 'third';
  });

  useEffect(() => {
    localStorage.setItem('aiPerspective', aiPerspective);
  }, [aiPerspective]);

  return (
    <SettingsContext.Provider value={{ aiPerspective, setAiPerspective }}>
      {children}
    </SettingsContext.Provider>
  );
}

export function useSettings() {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
}