import React from 'react';
import { Settings as SettingsIcon, HelpCircle } from 'lucide-react';
import { useSettings } from '../contexts/SettingsContext';

interface TooltipProps {
  content: string;
  example: string;
}

function Tooltip({ content, example }: TooltipProps) {
  return (
    <div className="group relative">
      <HelpCircle className="w-4 h-4 text-slate-400 hover:text-slate-600 dark:text-slate-500 dark:hover:text-slate-300" />
      <div className="absolute left-6 top-1/2 -translate-y-1/2 w-64 p-3 bg-white dark:bg-slate-800 rounded-lg shadow-lg border border-slate-200 dark:border-slate-700 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-50">
        <p className="text-sm text-slate-600 dark:text-slate-300 mb-2">{content}</p>
        <p className="text-xs text-slate-500 dark:text-slate-400 italic">Example: {example}</p>
      </div>
    </div>
  );
}

export default function Settings({ onClose }: { onClose: () => void }) {
  const { aiPerspective, setAiPerspective } = useSettings();

  const perspectives = {
    first: {
      label: "First Person",
      description: "Analysis from the writer's perspective",
      example: "I felt overwhelmed by the challenges I faced today..."
    },
    second: {
      label: "Second Person",
      description: "Analysis speaking directly to the writer",
      example: "You expressed feeling overwhelmed by the challenges you faced..."
    },
    third: {
      label: "Third Person",
      description: "Analysis from an observer's perspective",
      example: "The writer expressed feeling overwhelmed by the challenges they faced..."
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white dark:bg-slate-800 rounded-lg shadow-xl max-w-2xl w-full m-4">
        <div className="p-6 border-b border-slate-200 dark:border-slate-700">
          <div className="flex items-center gap-2 mb-4">
            <SettingsIcon className="w-6 h-6 text-slate-700 dark:text-slate-200" />
            <h2 className="text-2xl font-semibold text-slate-900 dark:text-white">
              Settings
            </h2>
          </div>

          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium text-slate-800 dark:text-slate-200 mb-4">
                AI Analysis Perspective
              </h3>
              <div className="space-y-4">
                {Object.entries(perspectives).map(([key, value]) => (
                  <div key={key} className="flex items-center gap-4">
                    <input
                      type="radio"
                      id={key}
                      name="perspective"
                      value={key}
                      checked={aiPerspective === key}
                      onChange={(e) => setAiPerspective(e.target.value as 'first' | 'second' | 'third')}
                      className="w-4 h-4 text-blue-500 border-slate-300 dark:border-slate-600 focus:ring-blue-500 dark:focus:ring-blue-600"
                    />
                    <label
                      htmlFor={key}
                      className="flex-1 flex items-center gap-2 text-slate-700 dark:text-slate-300"
                    >
                      <span>{value.label}</span>
                      <Tooltip content={value.description} example={value.example} />
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="p-6 bg-slate-50 dark:bg-slate-800/50 rounded-b-lg">
          <button
            onClick={onClose}
            className="w-full py-2 px-4 rounded-lg bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-200"
          >
            Save & Close
          </button>
        </div>
      </div>
    </div>
  );
}