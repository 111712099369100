import React from 'react';
import { Bot, Loader2, RefreshCw } from 'lucide-react';

interface AISummaryProps {
  summary: string | undefined;
  loading?: boolean;
  onGenerate: () => void;
  className?: string;
}

export default function AISummary({ summary, loading, onGenerate, className = '' }: AISummaryProps) {
  return (
    <div className={`rounded-lg bg-gradient-to-br from-blue-50 to-indigo-50 dark:from-blue-950/50 dark:to-indigo-950/50 p-4 ${className}`}>
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center gap-2">
          <Bot className="w-4 h-4 text-blue-600 dark:text-blue-400" />
          <h4 className="text-sm font-medium text-blue-600 dark:text-blue-400">
            AI Summary
          </h4>
        </div>
        {!loading && (
          <button
            onClick={onGenerate}
            className="text-xs px-2 py-1 rounded bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300 hover:bg-blue-200 dark:hover:bg-blue-900/50 flex items-center gap-1"
          >
            {summary ? (
              <>
                <RefreshCw className="w-3 h-3" />
                Regenerate
              </>
            ) : (
              'Generate'
            )}
          </button>
        )}
      </div>
      {loading ? (
        <div className="flex items-center gap-2 text-slate-500 dark:text-slate-400">
          <Loader2 className="w-4 h-4 animate-spin" />
          <span>Generating summary...</span>
        </div>
      ) : summary ? (
        <p className="text-slate-600 dark:text-slate-300">{summary}</p>
      ) : (
        <p className="text-slate-500 dark:text-slate-400 text-sm italic">
          No summary generated yet. Click generate to create one.
        </p>
      )}
    </div>
  );
}