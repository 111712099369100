import React, { createContext, useContext, useState, useEffect } from 'react';

interface ThemeContextType {
  isDark: boolean;
  isAIEnabled: boolean;
  toggleTheme: () => void;
  toggleAI: () => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [isDark, setIsDark] = useState(false);
  const [isAIEnabled, setIsAIEnabled] = useState(() => {
    const saved = localStorage.getItem('isAIEnabled');
    return saved !== null ? JSON.parse(saved) : true; // Default to true
  });

  useEffect(() => {
    const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    setIsDark(isDarkMode);
  }, []);

  useEffect(() => {
    if (isDark) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDark]);

  useEffect(() => {
    localStorage.setItem('isAIEnabled', JSON.stringify(isAIEnabled));
  }, [isAIEnabled]);

  const toggleTheme = () => {
    setIsDark(!isDark);
  };

  const toggleAI = () => {
    setIsAIEnabled(!isAIEnabled);
  };

  return (
    <ThemeContext.Provider value={{ isDark, isAIEnabled, toggleTheme, toggleAI }}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}