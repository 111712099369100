// Previous imports remain the same...

export default function UnsavedChangesModal({ onSave, onDiscard, onCancel }: UnsavedChangesModalProps) {
  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-white dark:bg-slate-800 rounded-lg shadow-xl max-w-md w-full">
        <div className="p-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-3">
              <AlertTriangle className="w-6 h-6 text-amber-500" />
              <h2 className="text-xl font-semibold text-slate-900 dark:text-white">
                Unsaved Changes
              </h2>
            </div>
            <button
              onClick={onCancel}
              className="text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
          
          <p className="text-slate-600 dark:text-slate-300 mb-6">
            You have unsaved changes. Would you like to save them before leaving?
          </p>

          <div className="flex justify-end gap-3">
            <button
              onClick={onDiscard}
              className="px-4 py-2 rounded-lg border border-slate-200 dark:border-slate-700 text-slate-600 dark:text-slate-300 hover:bg-slate-50 dark:hover:bg-slate-700/50"
            >
              Discard
            </button>
            <button
              onClick={onCancel}
              className="px-4 py-2 rounded-lg border border-slate-200 dark:border-slate-700 text-slate-600 dark:text-slate-300 hover:bg-slate-50 dark:hover:bg-slate-700/50"
            >
              Cancel
            </button>
            <button
              onClick={onSave}
              className="px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600"
            >
              Save & Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}